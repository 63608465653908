import React from "react"
import Container from "../components/Container"

import Layout from "../components/layout"
import PreferrLogo from "../components/Logos/PreferrLogo"
import SEO from "../components/seo"

const Preferr: React.FC = () => (
  <Layout>
    <SEO title="Preferr" />
    <Container className="mb-24">
      <PreferrLogo size={120} className="mb-8" />
      <div className="text-base font-sans text-gray-500">Founder</div>
      <div className="font-mono text-gray-500 text-sm">2020 - Present</div>
      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-12">
        <div className="font-sans text-sm mt-6 col-span-2">
          <p className="text-xs text-gray-500">
            TL;DR{" "}
            <a
              href="https://preferr.app/demo"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-100 transition duration-100 ease-in-out"
            >
              Click here for a live demo
            </a>
          </p>
          <p>
            During 2020, I began working on Preferr as a way to test my own
            mettle and see if I could build a product from scratch. I also
            wanted to find a way to blend my passion for good design and well
            crafted developer tools, and Preferr became that outlet.
          </p>
          <p>
            For quite some time, I'd wanted to find a way to remove some of the
            subjectivity from UI/UX design, particularly when it comes to the
            marketing heavy world of A/B testing.
          </p>
          <p>
            A/B testing products and services overwhelmingly cater to marketing
            and sales people who are trying to improve click through rates or
            get more paying conversions. There tends to be a very narrow use
            case for A/B testing in the world of software, and it's rarely
            focused on good design and what makes users enjoy using a website or
            app.
          </p>
          <p>
            With that in mind, I knew I wanted to create something that was
            flexible enough to address niche conversions that designers and
            developers wish they could measure, like if a long network response
            time results in a user leaving a page.
          </p>
          <p>
            I looked for examples of products that tout good developer
            experience as a hallmark feature, and naturally Stripe came to mind.
            Stripe spends tons of time and money creating tools that make life
            easier for developers and designers, and they do it with thorough
            documentation and thoughtful API design.
          </p>
          <p>
            So, taking inspiration from Stripe's stellar docs, I started working
            on Preferr.
          </p>
          <h3 className="font-medium text-gray-500 font-serif mt-12">
            Why React?
          </h3>
          <p>
            React is made for building user interfaces. It's popular, well
            documented, and is the de facto choice for most developers working
            on the frontend.
          </p>
          <p>
            It's also a design centric framework that focuses on programming
            patterns that are common to creating a good user experience. Plus,
            design tools like Framer and Figma have plugins or native features
            to export designs as React compatible JSX.
          </p>
          <p>I also know React quite well, so it was natural to start there.</p>
          <h3 className="font-medium text-gray-500 font-serif mt-12">
            How it works
          </h3>
          <p>
            Preferr generates a React code snippet whenever a user creates an
            A/B test. That code snippet is used to wrap two child components,
            and it will randomly render one of those components.
          </p>
          <p>
            I knew that I needed to persist the rendered component somehow
            because a constantly changing user interface is both a bad user
            experience and worthless in terms of statistical analysis. I also
            knew that speed was important. I didn't want to be making network
            requests to fetch a persisted component and risk delaying a render
            because of a slow internet connection.
          </p>
          <p>
            And I also didn't want to rely on any third party libraries like
            redux to store data. It was important that Preferr's only dependency
            be React, both for speed and so that when installed, the Preferr npm
            package didn't load up an app with extra fluff that I could've
            handled with native browser technologies.
          </p>
          <p>
            The obvious choice to handle both of those problems was to use
            localStorage. Preferr makes extensive use of localStorage to keep
            track of what components have been rendered as a result of an A/B
            test.
          </p>
          <p>
            As I mentioned earlier, a big part of Preferr's feature set is the
            freedom to define conversions however you want. To do that, I added
            a convert function to the JS library that can be called at any time.
            All it needs is an A/B test ID and it figures out which variant of a
            test converted, which visitor it converted for, and then records the
            conversion.
          </p>
          <p>
            Doing it that way meant that a dev could use that function anywhere
            in there code and that it wasn't explicitly tied to the component
            being tested.
          </p>
          <p>
            Once that part of the library was finished, I turned to the data
            side.
          </p>
          <h3 className="font-medium text-gray-500 font-serif mt-12">
            Data and statistical analysis
          </h3>
          <p>
            Preferr keeps track of impressions, conversions, and visitors. By
            default, visitors are anonymous but they can be identified if the
            developer using Preferr wants to assign attributes to them.
          </p>
          <p>
            Stats like conversion rate and total impressions are easy to
            calculate, but A/B tests aren't worth much without some more hearty
            statistical. Because Ruby is running the show on the backend, I knew
            it would be difficult to do any heavy statistical analysis. Ruby is
            great for a lot of things, but math isn't one of them.
          </p>
          <p>
            I knew that Python is the old standby for science and math related
            programming, but I couldn't just toss in some Python in a Ruby
            codebase. I'd never written a serverless function before, but I'd
            heard a lot about them and how they allow you to blend the best
            parts of multiple languages together, so I signed up for a
            Serverless account and got to work.
          </p>
          <p>
            The gold standard metric for determining the efficacy of an A/B test
            is the p-value. P-values reveal if the data collected is
            statistically significant, which in A/B testing means that one of
            the variants has performed better than the other to such a degree
            that you can be confident it was not just randomly better, but truly
            better.
          </p>
          <p>
            I enlisted my brother's help with Python and he taught me how to use
            SciPy and NumPy to run basic stats functions that would help me get
            a p-value.
          </p>
          <p>
            With that set up, all I needed to do was get the Ruby app to send
            data to my new AWS Lambda function. I didn't want to be calculating
            p-values whenever a new impression or conversion occurred becuase
            that could be thousands of times a minute. It's also not important
            that p-values be calculated in real time because they require a
            pretty large sample size to effectively calculate.
          </p>
          <p>
            I created a Sidekiq worker that runs every 10 minutes and sends data
            to the serverless Python function, which responds with a p-value
            that I then store in the A/B test table.
          </p>
          <h3 className="font-medium text-gray-500 font-serif mt-12">
            What's next?
          </h3>
          <p>
            The difficult task of marketing and selling! If I've learned
            anything over the course of my 7 year entrepreneurial journey, it's
            that sales and marketing are the most difficult aspects of any
            business. "If you build it, they will come" is not true in the
            slightest.
          </p>
          <p>
            I haven't put as much time into either as I need to in order for
            Preferr to stick, so I hope I can get that going fairly soon.
          </p>
        </div>
        <div className="p-4 bg-gray-900 rounded-lg">
          {/* <div className="font-mono text-gray-200 text-base text-center">
            Tech Stack
          </div> */}
          <div className="text-xs text-gray-600 font-mono">
            Languages/Frameworks
          </div>
          <ul className="list-none text-gray-200 font-sans text-sm mt-4">
            <li>Ruby on Rails (API only mode)</li>
            <li>GraphQL</li>
            <li>React</li>
            <li>TypeScript</li>
            <li>Python</li>
            <li>Sidekiq</li>
            <li>Postges</li>
            <li>Tailwind</li>
          </ul>
          <div className="text-xs text-gray-600 font-mono">
            Platforms/Services
          </div>
          <ul className="list-none text-gray-200 font-sans text-sm mt-4">
            <li>Heroku</li>
            <li>AWS Lambda</li>
            <li>Serverless</li>
            <li>Netlify</li>
            <li>Postmark</li>
            <li>Stripe</li>
          </ul>
        </div>
      </div>
    </Container>
  </Layout>
)

export default Preferr
